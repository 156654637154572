export function isUploadcareUrl<T extends string | null | undefined>(
  url: T,
): boolean {
  return url?.startsWith("https://ucarecdn.com/") || false;
}

// dimensions param format: https://uploadcare.com/docs/transformations/image/resize-crop/#operation-preview
export function scaleUploadcareUrl<T extends string | null | undefined>(
  url: T,
  dimensions?: string | number,
): T | string {
  if (typeof url !== "string") {
    return url;
  }

  if (!isUploadcareUrl(url)) {
    console.warn(
      "[scaleUploadcareUrl] Blocked attempt to apply transformations to non-Uploadcare url ",
    );

    return url;
  }

  let newUrl = url.replace(/-\/(preview|resize)\/.*?\//, "/");
  if (!newUrl.endsWith("/")) {
    newUrl += "/";
  }
  newUrl += "-/preview/";

  if (dimensions) {
    const formattedDimensions =
      typeof dimensions === "number"
        ? `${dimensions}x${dimensions}`
        : dimensions;
    newUrl += formattedDimensions + "/";
  }

  return newUrl;
}
